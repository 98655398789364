import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "decrementButton", "incrementButton", "alert"];

  onIncrement() {
    if (this.value == 10) {
      this.alertTarget.innerHTML = "More than 10 files cannot be uploaded";
      this.alertTarget.classList.remove("hidden");
    } else {
      this.value = parseInt(this.value) + 1;
      if (this.value >= 1) {
        this.alertTarget.classList.add("hidden");
      }
    }
  }

  onDecrement() {
    if (this.value == 1) {
      this.alertTarget.innerHTML = "At least 1 file should be uploaded";
      this.alertTarget.classList.remove("hidden");
    } else {
      this.value = this.value - 1;
      if (this.value <= 10) {
        this.alertTarget.classList.add("hidden");
      }
    }
  }

  get value() {
    return this.inputTarget.value;
  }

  set value(v) {
    this.inputTarget.value = v;
  }
}
