import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'calendar']

  onFocus() {
    this.calendarTarget.classList.remove('hidden')
  }

  onBlur(event) {
    if (!event.relatedTarget || !event.relatedTarget.classList.contains('calendar')) {
      this.calendarTarget.classList.add('hidden')
    }
  }

  connect() {
    console.log("date-input connected")
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.inputTarget.addEventListener('focus', this.onFocus)
    this.inputTarget.addEventListener('blur', this.onBlur)
  }

  disconnect() {
    console.log('date-input disconnected')
  }

  selectDate(event) {
    const value = event.target.getAttribute('data-value')
    console.log('Date selected', { value });
    this.inputTarget.value = value
    this.inputTarget.innerHTML = 'hello world'
    this.onBlur(event)
  }
}
