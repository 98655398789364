import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['consultantInput', 'adminInput']

  connect() {
    if (this.hasAdminInputTarget) {
      if (this.adminInputTarget.checked) {
        this.showAdminInput()
      } else {
        this.showConsultantInput()
      }
    }
  }

  toggleRole(event) {
    if (event.target.value === 'admin') {
      this.showAdminInput()
    } else {
      this.showConsultantInput()
    }
  }

  showAdminInput() {
    this.adminInputTarget.classList.remove('hidden')
    this.consultantInputTarget.classList.add('hidden')
  }

  showConsultantInput() {
    this.consultantInputTarget.classList.remove('hidden')
    this.adminInputTarget.classList.add('hidden')
  }
}
