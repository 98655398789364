import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["input", "view"];

  onChange(event) {
    console.log("input type changed", { value: event.target.value });
    const url = new URL(location.href);
    url.searchParams.set("type", event.target.value);
    window.location.assign(url.search);
    console.log("after location assign");
    Turbo.visit(window.location, { action: "replace" });
  }

  onShowInput(event) {
    if (this.inputTarget && this.viewTarget) {
      this.inputTarget.classList.remove("hidden");
      this.viewTarget.classList.add("hidden");
      event.preventDefault();
    }
  }

  onHideInput() {
    if (this.selectTarget) {
      this.selectTarget.classList.add("hidden");
      this.viewTarget.classList.remove("hidden");
    }
  }
}
