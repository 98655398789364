import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    key: String,
    default: Boolean
  }

  onNavigate() {
    const { hash } = window.location;
    console.log("onNavigate", { hash, key: this.keyValue });
    if (hash.endsWith(this.key) || (hash === '' && this.isDefault)) {
      this.activate()
    } else {
      this.deactivate()
    }
  }

  connect() {
    console.log("connected", { key: this.keyValue })
    this.onNavigate = this.onNavigate.bind(this)

    this.onNavigate()
    document.addEventListener('turbo:before-visit', (event) => {
      console.log('turbo:before-visit', event)
    })
    window.addEventListener('hashchange', this.onNavigate)
  }

  disconnect() {
    window.removeEventListener('hashchange', this.onNavigate)
  }

  activate() {
    this.element.classList.add('active')
  }

  deactivate() {
    this.element.classList.remove('active')
  }

  get key() {
    return this.keyValue;
  }

  get isDefault() {
    return this.defaultValue;
  }
}
