import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "choiceList", "choiceTemplate", "addButton"];

  onDelete(event) {
    event.target.closest(".choice").remove();

    // numbering choices is messed up, renumber them
    this.choiceListTarget.querySelectorAll(".choice").forEach((node, index) => {
      const label = node.querySelector("label");
      label.innerHTML = `Option ${index + 1}`;
      const input = node.querySelector("input");
      input.setAttribute("id", `option_${index + 1}`);
    });
    event.preventDefault();
  }

  onAdd(event) {
    console.log("add called", { target: event.target });
    try {
      const template = this.choiceTemplateTarget.content.cloneNode(true);
      const label = template.querySelector("label");
      label.innerHTML = `Option ${this.inputTargets.length + 1}`;
      const input = template.querySelector("input");
      input.setAttribute("id", `option_${this.inputTargets.length + 1}`);
      this.choiceListTarget.append(template);
    } catch (e) {
      console.log("failed to process onAdd", e);
    }
    event.preventDefault();
  }

  connect() {
    console.log("choice-question-options connected");
  }
}
