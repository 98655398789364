import { Controller } from "@hotwired/stimulus";
import "whatwg-fetch";

export default class extends Controller {
  static targets = ["text", "link"];
  static values = {
    verificationToken: String,
    timeRemaining: Number,
  };

  updateText() {
    this.timeRemainingValue = this.timeRemainingValue - 1;
    if (this.timeRemainingValue === 0) {
      this.linkTarget.classList.remove("hidden");
      this.textTarget.classList.add("hidden");
      if (this.updateTextInterval) {
        clearInterval(this.updateTextInterval);
      }
    } else {
      this.textTarget.classList.remove("hidden");
      this.linkTarget.classList.add("hidden");
    }

    let timeString = "";
    if (this.timeRemainingValue % 60 !== 0) {
      timeString = `${this.timeRemainingValue % 60} seconds`;
    }

    if (Math.floor(this.timeRemainingValue / 60) > 0) {
      if (timeString.length > 0) {
        timeString = `${Math.floor(
          this.timeRemainingValue / 60
        )} minutes and ${timeString}`;
      } else {
        timeString = `${Math.floor(this.timeRemainingValue / 60)} minutes`;
      }
    }
    this.textTarget.innerHTML = `Did not receive OTP? Send again in ${timeString}`;
  }

  restartTimer() {
    this.timeRemainingValue = 150;
    this.updateTextInterval = setInterval(this.updateText, 1000);
  }

  async onRegenerate() {
    const csrfToken = document
      .querySelector("meta[name=csrf-token]")
      .getAttribute("content");

    await fetch("/regenerate-otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: this.verificationTokenValue,
        authenticity_token: csrfToken,
      }),
    });

    this.restartTimer();
  }

  connect() {
    this.onRegenerate = this.onRegenerate.bind(this);
    this.updateText = this.updateText.bind(this);
    this.restartTimer = this.restartTimer.bind(this);

    console.log("time remaining", this.timeRemainingValue);

    if (this.hasTextTarget) {
      this.updateTextInterval = setInterval(this.updateText, 1000);
    }
  }

  disconnect() {
    if (this.updateTextInterval) {
      clearInterval(this.updateTextInterval);
    }
  }
}
