import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  onBeforeFetchRequest(event) {
    const params = event.detail.fetchOptions.body;
    const newParams = new URLSearchParams();
    let options = [];
    params.entries().forEach(([key, value]) => {
      const newKey = key.replace(
        /(single_choice|open|multi_choice|file_upload)_question(.*)/,
        "screening_question$2"
      );
      newParams.append(newKey, value);
    });
    if (options.length > 0) {
    }
    console.log("Before form fetch request", { params, newParams, options });
    event.detail.fetchOptions.body = newParams;
  }

  connect() {
    console.log("screening-question-form connected");
    this.onBeforeFetchRequest = this.onBeforeFetchRequest.bind(this);
    this.element.addEventListener(
      "turbo:before-fetch-request",
      this.onBeforeFetchRequest
    );
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:before-fetch-request",
      this.onBeforeFetchRequest
    );
  }
}
